import { writable } from "svelte/store";

export enum NotificationType {
  Info,
  Success,
  Warning,
  Error
}

/**
 * Enumerates milliseconds as values
 */
export enum NotificationExpiration {
  FAST = 1000,
  NORMAL = 2000,
  SLOW = 4000,
  NEVER = -1
}

export type Notification = {
  readonly onClose?: () => unknown;
  closed: boolean;
  readonly isTolgee: boolean;
} & NotificationCreationProps;

export interface NotificationCreationProps {
  /**
   * Defaults to {@link NotificationType.Info} if not defined
   */
  readonly type?: NotificationType;
  /**
   * Does not do anything by default
   */
  readonly onClose?: () => unknown;
  readonly isTolgee?: boolean;
  readonly message: string;
}

const createNotificationStore = () => {
  const { subscribe, update } = writable<Notification[]>([]);

  /**
   * Manually removes a notification from the stack by settings its state to closed
   * @param notification Which notification to remove
   */
  const removeNotification = (notification: Notification) => {
    update((allNotifications) => {
      const index = allNotifications.indexOf(notification);
      if (index < 0 || index >= allNotifications.length) {
        return allNotifications;
      }
      allNotifications[index].closed = true;
      if (allNotifications.every((notification) => notification.closed)) {
        return [];
      }
      return allNotifications;
    });
  };

  /**
   * Creates a new notification in the top right corner
   * @param notification The Data needed to construct the notification
   * @param expire When the notification should remove itself. Defaults to {@link NotificationExpiration.NORMAL}
   */
  const createNotification = (
    notification: NotificationCreationProps,
    expire = NotificationExpiration.NORMAL
  ) => {
    const notificationPayload = {
      ...notification,
      type: notification.type ?? NotificationType.Info,
      closed: false,
      isTolgee: notification.isTolgee ?? false
    };
    update((last) => [...last, notificationPayload]);
    if (expire !== NotificationExpiration.NEVER) {
      setTimeout(() => {
        removeNotification(notificationPayload);
      }, expire);
    }
  };


  /**
   * Creates a new notification in the top right corner but replaces message with the key stored in tolgee
   * @param notification The Data needed to construct the notification
   * @param expire When the notification should remove itself. Defaults to {@link NotificationExpiration.NORMAL}
   */
  const createTolgeeNotification = (
    notification: NotificationCreationProps,
    expire = NotificationExpiration.NORMAL
  ) =>
    createNotification(
      {
        ...notification,
        isTolgee: true
      },
      expire
    );

  return {
    subscribe,
    removeNotification,
    createNotification,
    createTolgeeNotification
  };
};

export const notifications = createNotificationStore();
